<template>
  <div>
    <div id="product" v-if="current_active_module">
      <!-- loader starts here  -->
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>

      <!-- ends here -->
      <v-data-table
        :headers="headers"
        v-model="selected"
        :single-select="singleSelect"
        show-select
        item-key="product_id"
        hide-default-footer
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items="desserts"
        :options.sync="options"
        class="elevation-1"
        @page-count="pageCount = $event"
      >
        <template v-slot:top>
          <div id="product__filter">
            <v-col cols="5">
              <v-text-field v-model="search_product_id" label="Product ID" />
            </v-col>

            <v-col cols="4" v-if="displayButtons">
              <v-btn class="mx-2" small color="primary" @click="search">
                <strong>Search</strong>
              </v-btn>
              <v-btn class="mx-2" small color="primary" @click="reset">
                <strong>Reset</strong>
              </v-btn>
            </v-col>
          </div>
          <searchForm
            @data="data"
            @search="search"
            @reset="reset"
            @addCompany="addCompany"
            @removeCompany="removeCompany"
            :formType="'PRODUCT_ARCHIEVE'"
            @showButtons="showButtons"
            :stateName="'PmTempProduct'"
          />
          <v-container class="grey lighten-5" id="product__container">
            <v-row no-gutters>
              <v-col cols="14">
                <div style="float: right">
                  <v-btn color="primary" small @click.stop="deleteproduct()"
                    >Delete</v-btn
                  >
                </div>
                <div style="float: right">
                  <v-btn color="primary" small @click.stop="restoreproduct()"
                    >Restore</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <!-- product preview column -->
        <template
          v-slot:item.product_id="{ item }"
          style="display: inline-flex"
        >
          {{ item.product_id }}
        </template>
        <template v-slot:item.company="{ item }">
          <span>{{ companyName(item.product_id) }}</span>
        </template>
        <template v-slot:item.modify_date="{ item }">
          <span>{{ formateDate(item.modify_date) }}</span>
        </template>
        <template v-slot:item.product_status="{ item }">
          <span>{{ displayStatus(item.product_status) }}</span>
        </template>
        <!-- product name -->
        <template v-slot:item.pnamelink="{ item }">
          <span>
            <a
              style="text-decoration: none"
              @click="addItem(item)"
              title="Add Product"
              >{{ item.productName }}
            </a></span
          >
        </template>
        <template v-slot:item.userpopup="{ item }">
          <span
            @click.stop="openlogmodal(item.product_id, item.muid)"
            style="color: blue; cursor: pointer"
          >
            {{ userName(item.product_id) }}</span
          >
        </template>
        <template v-slot:item.mchannel_sector_audience="{ item }">
          <span>{{ mchannelSectorAudience(item) }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          {{ item.product_id }} / {{ item.muid }}
          <div v-if="item.product_status == 11">
            <v-icon small class="mr-2" @click="addItem(item)">mdi-plus</v-icon>
            <v-icon small @click="editItem(item)">mdi-pencil</v-icon>
          </div>
          <div v-else>
            <v-icon small class="mr-2" @click="addItem(item)"
              >mdi-format-list-bulleted</v-icon
            >
          </div>
        </template>
      </v-data-table>
      <div class="text-center pt-4 pb-4">
        <Pagination
          :isloading="isLoading"
          :startRecord="startRecord"
          :currentPage="currentPage"
          :lastPage="lastPage"
          :lastRecord="lastRecord"
          :totRecords="totRecords"
          :isCurrentPageClass="isCurrentPageClass"
          :perpage="perpage"
          :getLastPageClass="getLastPageClass"
          :totPage="totPage"
          :getPages="getPages"
          @handlePerPage="handlePerPage"
          @paginate="paginate"
          @last="last"
          @getDataByPage="getDataByPage"
          :showPerPage="showPerPage"
        />
      </div>
      <!-- dialog box-->
      <v-dialog v-model="massupdatedialog" max-width="650">
        <MassUpdate
          :selected="selected"
          @massupdatedialogStatus="massupdatedialogStatus"
        />
      </v-dialog>

      <!-- dialog box-->
      <!-- dialog box-->
      <v-dialog v-model="productadmindialog" max-width="550">
        <v-card>
          <v-card-title class="headline"> Product Activity Log </v-card-title>
          <br />
          <v-data-table
            :headers="userheaders"
            hide-default-footer
            :items="logdata"
            class="elevation-1"
          >
          </v-data-table>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green darken-1"
              text
              @click="productadmindialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div id="product_div" v-else>
      <div id="product_loader"></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Pagination from "./component/Pagination.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import searchForm from "./component/searchForm.vue";
import MassUpdate from "./component/MassUpdate";
import Tabs from "./component/Tabs.vue";

export default {
  name: "PrintMediaTemp",
  components: {
    Loading,
    Pagination,
    searchForm,
    MassUpdate,
    Tabs,
  },
  data() {
    return {
      closeOnConfirm: false,
      search_product_id: "",
      massupdatedialog: false,
      preview_loaded: false,
      hover_product_id: "",
      menu: "",
      filtermuid: "",
      isLoading: true,
      fullPage: true,
      productadmindialog: false,
      logdata: [],
      isLoading: false,
      fullPage: true,
      rowsPerPageItems: [25, 50, 100],
      sortDesc: true,
      sortBy: "modify_date",
      singleSelect: false,
      selected: [],
      search_product_id: "",
      selectedcompanyid: "",
      search_product_name: "",
      selectedstateid: "",
      sort: "",
      loading: true,
      options: {},
      showPerPage: true,
      userheaders: [
        {
          text: "UserName",
          value: "userid",
          width: "100px",
          class: "",
        },
        {
          text: "Action",
          align: "start",
          value: "ProductActivity",
          width: "120px",
          class: "",
        },
        {
          text: "Date",
          value: "logDate",
          width: "120px",
          class: "",
        },
      ],
      headers: [
        {
          text: "Product Id",
          align: "start",
          sortable: true,
          value: "product_id",
          class: "v-data-table-header",
          width: "12%",
        },
        {
          text: "Date",
          value: "modify_date",
          width: "120px",
          class: "v-data-table-header",
          width: "12%",
        },
        {
          text: "Entry Id",
          value: "entry_id",
          width: "120px",
          class: "v-data-table-header",
          width: "12%",
        },
        {
          text: "Product",
          value: "product_name",
          width: "180px",
          sortable: true,
          class: "v-data-table-header",
          width: "12%",
        },
        {
          text: "Status",
          value: "product_status",
          width: "150px",
          sortable: true,
          class: "v-data-table-header",
          width: "12%",
        },

        {
          text: "Company",
          value: "company",
          width: "180px",
          sortable: false,
          class: "v-data-table-header",
          width: "15%",
        },
        {
          text: "Sector/Audience",
          value: "mchannel_sector_audience",
          sortable: false,
          width: "200px",
          class: "v-data-table-header",
          width: "12%",
        },
        {
          text: "Last User",
          value: "userpopup",
          sortable: false,
          class: "v-data-table-header",
          width: "12%",
        },
      ],
      logmuid: "",
      data: "temp_product",
      sectors: [],
      product_status: [],
      displayButtons: true,
    };
  },
  mounted() {
    var view = this,
      raw_data_endpoint = view.$url("PRODUCT_RAW_DATA");
    view.$productrouteconst.fetchAllProductStatus(view);
    view.$store
      .dispatch("PmTempProduct/setIsSearch", true)
      .then(function () {
        return view.$store.dispatch("PmTempProduct/setMustNot", {
          key: "product_status",
          value: view.$productrouteconst.APPROVED,
        });
      })
      .then(function () {
        return view.$store.dispatch("PmTempProduct/setMediaChannels", [
          view.$productrouteconst.PRINT,
        ]);
      });
    view
      .$fetch({ requiresAuth: true })
      .get(raw_data_endpoint)
      .then((response) => {
        view.sectors = response.data.payload;
      })
      .catch((e) => console.log(e));
    view.product_status = view.$productrouteconst.product_status();
  },
  computed: {
    ...mapGetters({
      current_active_module: "Rawdata/getCurrentActiveModule",
    }),
    sector_data: {
      get: function () {
        return this.$store.getters["EcProcessedProduct/getSectors"];
      },
      set: function (arr) {
        var view = this,
          all = view.sectors.filter((e) => e.key !== "0"),
          selected = arr.filter((e) => e === "0"),
          saved = view.$store.getters["EcProcessedProduct/getSectors"],
          flag = true;
        if (selected.length > 0) {
          if (
            arr.filter((e) => e !== "0").length <
            saved.filter((e) => e !== "0").length
          ) {
            arr = arr.filter((e) => e !== "0");
            flag = false;
          } else {
            all.forEach(function (e) {
              arr.push(e.key);
            });
          }
        }
        if (saved.includes("0") && !arr.includes("0")) {
          if (flag) {
            arr = new Array();
          }
        }
        arr = [...new Set(arr)];
        view.$store.dispatch("EcProcessedProduct/setSectors", arr);
      },
    },
    product_status_data: {
      get: function () {
        return this.$store.getters["PmTempProduct/getProductStatus"];
      },
      set: function (arr) {
        var view = this,
          all = view.product_status.filter((e) => e.key !== "ALL"),
          selected = arr.filter((e) => e === "ALL"),
          saved = view.$store.getters["PmTempProduct/getProductStatus"],
          flag = true;
        if (selected.length > 0) {
          if (
            !(
              saved.filter((e) => e.key !== "ALL").length >=
              arr.filter((e) => e.key !== "ALL").length
            )
          ) {
            all.forEach(function (e) {
              arr.push(e.key);
            });
          } else {
            arr = arr.filter((e) => e !== "ALL");
            flag = false;
          }
        }
        if (saved.includes("ALL") && !arr.includes("ALL")) {
          if (flag) {
            arr = new Array();
          }
        }
        arr = [...new Set(arr)];
        this.$store.dispatch("PmTempProduct/setProductStatus", arr);
      },
    },
    desserts: {
      get: function () {
        return this.$store.getters["PmTempProduct/getDesserts"];
      },
      set: function () {},
    },
    totalDesserts: {
      get: function () {
        return this.$store.getters["PmTempProduct/getTotalDesserts"];
      },
      set: function () {},
    },
    totRecords: {
      get: function () {
        return this.$store.getters["PmTempProduct/getTotRecords"];
      },
      set: function () {},
    },
    pageCount: {
      get: function () {
        return this.$store.getters["PmTempProduct/getPageCount"];
      },
      set: function () {},
    },
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return this.lastPage;
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.$store.getters["PmTempProduct/getPage"];
    },
    lastPage: function () {
      return this.$store.getters["PmTempProduct/getLastPage"];
    },
    perpage: function () {
      return this.$store.getters["PmTempProduct/getPerPage"];
    },
    page: function () {
      return this.$store.getters["PmTempProduct/getPage"];
    },
  },
  methods: {
    deleteproduct() {
      var view = this;
      if (view.selected.length > 0) {
        view.$swal
          .fire({
            title: "Are you sure ?",
            text: "This will remove product permanently.",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, remove it !",
            cancelButtonText: "No, cancel !",
            closeOnConfirm: false,
            closeOnCancel: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              // delete endpoint
              var posted_data = {};
              var data = [];
              for (var l in this.selected) {
                let local = {};
                let modified_date = this.selected[l].modify_date;
                let md = modified_date.split("-");
                local["product_id"] = this.selected[l].product_id;
                local["index_name"] = "products_" + md[0];
                data.push(local);
              }
              posted_data["data"] = data;
              let delete_data = JSON.stringify(posted_data);
              let delelete_endpoint = view.$url("HARD_DELETE");
              this.$fetch({ requiresAuth: true })
                .post(delelete_endpoint, posted_data)
                .then((response) => {
                  this.$swal.fire({
                    icon: "success",
                    title: "Product Delete",
                    text: "Product has been deleted permanently",
                  });
                  view.selected = [];
                  view.getData();
                  view.selected = [];
                })
                .catch(function (response) {
                  if (response.response.status == 401) {
                    window.location.href;
                  }
                });
            } else {
              view.selected = [];
            }
          });

        //console.log(this.selected)
      } else {
        view.$swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "Please select ProductID",
        });
      }
    },
    restoreproduct() {
      var view = this;
      this.loading = true;
      if (view.selected.length > 0) {
        view.$swal
          .fire({
            title: "Are you sure ?",
            text: "This will restore product.",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, restore it !",
            cancelButtonText: "No, cancel !",
            closeOnConfirm: false,
            closeOnCancel: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              // delete endpoint
              view.loading = true;
              var posted_data = {};
              var data = [];
              for (var l in this.selected) {
                let local = {};
                let modified_date = this.selected[l].modify_date;
                let md = modified_date.split("-");
                local["product_id"] = this.selected[l].product_id;
                local["muid"] = this.selected[l].muid;
                local["product_status"] = this.selected[l].product_status;
                local["index_name"] = "products_" + md[0];
                data.push(local);
              }
              posted_data["data"] = data;
              let delete_data = JSON.stringify(posted_data);
              let delelete_endpoint = view.$url("PRODUCT_RESTORE");
              this.isLoading = true;
              this.$fetch({ requiresAuth: true })
                .post(delelete_endpoint, posted_data)
                .then((response) => {
                  this.isLoading = false;
                  this.$swal.fire({
                    icon: "success",
                    title: "Product Restore",
                    text: "Product has been restored successfully",
                  });
                  console.log(JSON.stringify(posted_data));
                  view.selected = [];
                  view.getData();
                })
                .catch(function (response) {
                  if (response.response.status == 401) {
                    window.location.href;
                  }
                });
            } else {
              view.selected = [];
            }
          });

        //console.log(this.selected)
      } else {
        view.$swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "Please select ProductID",
        });
      }
    },
    formateDate: function (d) {
      return this.$utils.formateDate(d);
    },
    manage_pdf(product_id) {
      var view = this;
      let url = view.$url("MANANGE_PDF_URL") + product_id;
      url = url.replace(/%20/g, " ");
      window.open(
        url,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,,width=800,height=800"
      );
    },
    displayStatus: function (i) {
      var view = this,
        all_status = view.$store.getters["Rawdata/getProductStatus"],
        filtered = all_status.filter((o) => o.key_id === i);
      if (Array.isArray(filtered) && filtered.length > 0) {
        return filtered[0].description;
      }
      return "";
    },
    showButtons: function (o) {
      this.displayButtons = !this.displayButtons;
    },
    massupdatedialogStatus(o) {
      var view = this;
      view.massupdatedialog = false;
      view.selected = [];
      if (o == "save") view.getData();
    },
    massproductupdate() {
      var view = this;
      if (view.selected.length > 0) {
        view.massupdatedialog = true;
      } else {
        view.$swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "Please select ProductID",
        });
      }
    },
    displaypreview(product_id) {
      this.imagelist = [];
      this.preview_loaded = false;
      this.hover_product_id = product_id;
      let manage_image =
        this.$url("CLIENT_FRONTEND") + "managepdf/" + product_id;
      this.manage_pdf_url = manage_image;
      let token = this.$utils.getAccessToken();
      //this.loading = true
      let temproduct_url =
        this.$url("PRODUCT_PREVIEW") +
        "?product_id=" +
        product_id +
        "&product_type=ProductImg";
      this.pdf_link =
        this.$url("DIRECT_MAIL_PDF") + "?product_id=" + product_id;
      this.$fetch({ requiresAuth: true })
        .get(temproduct_url, {
          //headers
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          let items = response.data.payload;
          this.preview_loaded = true;
          this.imagelist = items;
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    filterdata() {
      this.getData();
    },
    reset() {
      var view = this;
      view.search_product_id = "";

      view.$store.dispatch("PmTempProduct/setPage", 1);
      view.$store.dispatch("PmTempProduct/setPerPage", 10);
      this.selected = [];
      view.getDataByPage(1);
    },
    getDataByPage(n) {
      let view = this,
        paging = view.$store.getters["PmTempProduct/getPaging"],
        obj = paging.filter((e) => e.page_number === n);
      view.selected = [];
      view.$store
        .dispatch("PmTempProduct/setPage", n)
        .then(function () {
          return view.$store.dispatch("PmTempProduct/setPagingObj", obj);
        })
        .then(function () {
          view.getData();
        });
    },
    handlePerPage(n) {
      let view = this;
      view.$store
        .dispatch("PmTempProduct/setPage", 1)
        .then(function () {
          return view.$store.dispatch("PmTempProduct/setPerPage", n);
        })
        .then(function () {
          view.options.itemsPerPage = n;
          view.getData();
        });
    },
    paginate(n) {
      if (
        parseInt(n) === parseInt(0) &&
        parseInt(this.currentPage) === parseInt(1)
      ) {
        return;
      }
      if (parseInt(n) === parseInt(-1)) {
        if (parseInt(this.currentPage) <= parseInt(1)) {
          return;
        }
      }
      if (parseInt(n) === parseInt(1)) {
        if (parseInt(this.currentPage) >= parseInt(this.lastPage)) {
          return;
        }
      }
      let view = this,
        paging = view.$store.getters["PmTempProduct/getPaging"],
        obj = [];
      n =
        parseInt(n) === parseInt(0)
          ? parseInt(1)
          : parseInt(n) + parseInt(view.currentPage);
      obj = paging.filter((e) => e.page_number === n);
      view.$store
        .dispatch("PmTempProduct/setPagingObj", obj)
        .then(function () {
          return view.$store.dispatch("PmTempProduct/setPage", n);
        })
        .then(function () {
          view.getData();
        });
    },
    last() {
      let view = this;
      view.$store
        .dispatch("PmTempProduct/setPage", view.lastPage)
        .then(function () {
          view.getData();
        });
    },
    openlogmodal(product_id, muid) {
      var view = this;
      view.productadmindialog = true;
      view.isLoading = true;
      view.logdata = [];
      let logurl =
        view.$url("PRODUCT_ADMIN_LOG") + product_id + "?type=process";
      let token = view.$utils.getAccessToken();
      this.$fetch({ requiresAuth: true })
        .get(logurl, {
          //headers
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          view.isLoading = false;
          view.logdata = response.data.payload;
          view.logmuid = muid;
        })
        .catch(function (response) {
          if (response.response.status == 401) {
            window.location.href;
          }
        });
    },
    getData: function () {
      let view = this,
        data = {};
      view.loading = true;
      view.isLoading = true;
      data["request"] = view.$store.getters["PmTempProduct/getRequestPayload"];
      if (view.$store.getters["PmTempProduct/getIsSearch"]) {
        console.log(view.$store.getters["PmTempProduct/getSearch"]);
        let search_data = view.$store.getters["PmTempProduct/getSearch"];

        data["search"] = {
          product_id: view.search_product_id,
          product_name: search_data["product_name"],
          product_status: search_data["product_status"],
          sectors: search_data["sectors"],
          entry_id: search_data["entry_id"],
          companies: search_data["companies"],
          users: search_data["users"],
          ocr: search_data["ocr"],
          muid: search_data["muid"],
          sorting: [
            {
              field: this.sortBy,
              direction: this.sortDesc,
            },
          ],
        };
      }
      view
        .$fetch({
          requiresAuth: true,
          operation: "add",
          vueScope: view,
        })
        .post(view.$url("PRODUCT_ARCHIEVE"), data)
        .then((response) => {
          let data = response.data.payload,
            lastPage = 0;
          view.desserts = data.payload;
          lastPage = Math.ceil(
            data.totalProducts / view.$store.getters["PmTempProduct/getPerPage"]
          );
          view.$store.dispatch("PmTempProduct/setDesserts", data.payload);
          view.$store.dispatch(
            "PmTempProduct/setTotalDesserts",
            data.totalProducts
          );
          view.$store.dispatch(
            "PmTempProduct/setTotRecords",
            data.totalProducts
          );
          view.$store.dispatch("PmTempProduct/setPageCount", lastPage);
          view.$store.dispatch("PmTempProduct/setExtraData", data.extra_data);
          view.$store.dispatch(
            "PmTempProduct/setTotProducts",
            data.totalProducts
          );
          view.$store.dispatch("PmTempProduct/setLastPage", lastPage);
          if ("paging" in data) {
            if (Array.isArray(data.paging)) {
              view.$store.dispatch("PmTempProduct/setPaging", data.paging);
            }
          }
          view.loading = false;
          view.isLoading = false;
        })
        .catch(function (e) {
          view.loading = false;
          view.isLoading = false;
          console.log(e);
        });
    },
    addItem(item) {
      var view = this;
      let product_id = "";
      product_id = item.product_id;
      if (product_id != "") {
        let enpointURL =
          view.$url("ADD_PRODUCT_FORM_URL") +
          product_id +
          "?ec_content_type=print_media";
        window.open(enpointURL, "_blank");
      }
    },
    editItem(item) {
      var view = this;
      let muid = "";
      muid = item.muid;
      if (muid != "") {
        let enpointURL = view.$url("EDIT_PRODUCT_FORM_URL") + muid;
        window.open(enpointURL, "_blank");
      }
    },
    companyName(id) {
      let extradata = this.$store.getters["PmTempProduct/getExtraData"];
      return id in extradata
        ? "company_name" in extradata[id]
          ? this.$utils.checkVal(extradata[id]["company_name"])
          : "--"
        : "--";
    },
    mchannelSectorAudience(i) {
      let view = this,
        str = "",
        extradata = view.$store.getters["PmTempProduct/getExtraData"],
        sec_name = "",
        pid = i.product_id,
        audience = "",
        str1 = "";
      // str= str + view.$utils.checkVal(i.mChannelName) + '/'
      // sec_name = pid in extradata ? ('sector_name' in extradata[pid] ? extradata[pid]['sector_name'] : '--') : '--'
      // str = str + view.$utils.checkVal(sec_name) + '/'
      str1 = str1 + extradata[pid]["sector_name"] + ",";
      //          for (var i = 0; i <= extradata[pid]["sector_name"].length - 1; i++) {
      //     str1 = str1 + extradata[pid]["sector_name"][i] + ",";
      //   }
      str = str1.substring(0, str1.length - 1) + "/";

      audience =
        pid in extradata
          ? "audience" in extradata[pid]
            ? extradata[pid]["audience"]
            : "--"
          : "--";
      str = str + view.$utils.checkVal(audience);
      return str;
    },
    userName(i) {
      let extradata = this.$store.getters["PmTempProduct/getExtraData"],
        val =
          i in extradata
            ? "admin_user" in extradata[i]
              ? extradata[i]["admin_user"]
              : "--"
            : "--";
      return val; //this.$utils.checkVal(val)
    },
    search: function () {
      var view = this;
      view.selected = [];
      view.$store
        .dispatch("PmTempProduct/setPage", 1)
        .then(function () {
          view.$store.dispatch(
            "PmTempProduct/setProductId",
            view.search_product_id
          );
          return view.$store.dispatch("PmTempProduct/setIsSearch", true);
        })
        .then(function () {
          view.getData();
        });
    },
    addCompany: function (c) {
      this.$store.dispatch("PmTempProduct/addCompany", c);
    },
    removeCompany: function (c) {
      this.$store.dispatch("PmTempProduct/removeCompany", c);
    },
  },
  watch: {
    options: {
      handler(o) {
        var view = this,
          sortBy = o.sortBy,
          sortDesc = o.sortDesc;
        if (Array.isArray(sortBy) && Array.isArray(sortDesc)) {
          if (sortBy.length > 0 && sortDesc.length > 0) {
            view.$store
              .dispatch("PmTempProduct/setPage", 1)
              .then(function () {
                return view.$store.dispatch("PmTempProduct/setSorting", {
                  field: sortBy[0],
                  direction: sortDesc[0],
                });
              })
              .then(function () {
                view.getData();
              });
          }
        }
      },
    },
  },
};
</script>

<style lang="sass" scoped>
@import './../../sass/product'
</style>
<style >
.previewcontainer {
  position: relative;
}
.pdfcontent {
}
.v-icon--right {
  margin-left: 0px;
}
.complist ul {
  padding-left: 0;
  list-style: none;
}
.complist li {
  list-style: none;
  float: none;
}
.complist span {
  position: relative;
  margin: 0 5px 0 5px;
  font-size: 14px;
  cursor: pointer;
  color: #00a4e4;
}
.sectorlist ul {
  padding-left: 0;
  list-style: none;
}
.sectorlist ul li {
  float: none;
}
.sectorlist ul li {
  margin: 0 5px 0 5px;
  font-size: 14px;
  cursor: pointer;
  color: #00a4e4;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 11px;
  font-weight: bold;
  color: #fff !important;
}
.mx-3 {
  overflow-x: hidden;
}
.v-menu__content {
  box-shadow: none;
}
.v-application .text-center {
  text-align: left !important;
}
</style>
